import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const { Caption } = CheckboxTheme;

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    label: ['text-content-sm'],
    input: [
      'active:before:scale-0',
      'active:bg-gray-100',
      'active:shadow-gray-900',
      'after:-translate-x-1/2',
      'after:-translate-y-2/3',
      'after:absolute',
      'after:block',
      'after:border-b-2',
      'after:border-gray-900',
      'after:border-l-2',
      'after:h-1.25',
      'after:left-1/2',
      'after:origin-center',
      'after:scale-0',
      'after:top-1/2',
      'after:transition-all',
      'after:w-2.5',
      'appearance-none',
      'before:-translate-x-1/2',
      'before:-translate-y-1/2',
      'before:-z-2',
      'before:absolute',
      'before:bg-linen-300',
      'before:block',
      'before:h-[130%]',
      'before:left-1/2',
      'before:rounded',
      'before:scale-0',
      'before:top-1/2',
      'before:transition-all',
      'before:w-[130%]',
      'bg-white',
      'checked:after:-rotate-45',
      'checked:after:scale-100',
      'focus:before:scale-100',
      'focus:shadow-black',
      'h-5',
      'hover:before:scale-100',
      'hover:shadow-black',
      'p-1.25',
      'relative',
      'rounded-[1px]',
      'shadow-gray-900',
      'shadow-inset-1',
      'transition-all',
      'w-5',
    ],
  },
  variants: {
    variant: {
      alternative: {
        label: ['text-content-sm'],
        input: [
          'active:border-0',
          'active:outline-0',
          'after:-translate-x-1/2',
          'after:-translate-y-1/2',
          'after:absolute',
          'after:bg-black',
          'after:block',
          'after:h-2/3',
          'after:left-1/2',
          'after:origin-bottom',
          'after:rounded-full',
          'after:top-1/2',
          'after:transition-all',
          'after:w-0.5',
          'appearance-none',
          'before:-translate-x-1/2',
          'before:-translate-y-1/2',
          'before:absolute',
          'before:bg-black',
          'before:block',
          'before:h-0.5',
          'before:left-1/2',
          'before:origin-left',
          'before:rounded-full',
          'before:top-1/2',
          'before:transition-all',
          'before:w-2/3',
          'border-black',
          'checked:after:h-1/2',
          'checked:after:left-[41%]',
          'checked:after:rotate-45',
          'checked:after:top-2/5',
          'checked:after:translate-x-0',
          'checked:before:left-2/5',
          'checked:before:rotate-45',
          'checked:before:top-2/5',
          'checked:before:translate-y-0',
          'checked:before:w-1/3',
          'focus:border',
          'focus:outline-[3px]',
          'h-9',
          'hover:outline-[3px]',
          'outline-0',
          'outline-linen-300',
          'outline',
          'relative',
          'rounded-full',
          'shadow-md',
          'transition-all',
          'w-9',
        ],
      },
    },
  },
});

export default Object.assign(Checkbox, { Caption });
